import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { defaultCreateNewAnalysisState } from "@containers/AnalysisTool/AnalysisTool";
import {
  IAnalysisData,
  ICurrentDate,
  IDrawerCreate,
  IFilter,
  INewAnalysisData,
  INewAnalysisRecord,
} from "@containers/AnalysisTool/AnalysisTool.types";
import { getTasks, tasksSelector } from "@redux/slices/tasks";
import { projectsSelector } from "@redux/slices/projects";
import Hooks from "@helpers/hooks";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Select,
  Table,
  Input,
  Popconfirm,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, {FC, useCallback, useEffect, useState} from "react";
import styles from "./CreateNewAnalysis.module.scss";
import {
  getResource,
  taskResourcesSelector,
} from "@redux/slices/taskResources";
import {
  analysisTypeSelector,
  getAnalysisType,
} from "@redux/slices/analysisType";
import {
  getAnalysis,
  postAnalysis,
  updateAnalysis,
} from "@redux/slices/analysis";
import analysisApi from "@api/analysis";
import _ from "lodash";
import { authSelector } from "@redux/slices/auth";
import moment from "moment";
import TasksApi from "@api/tasks";
import { PayloadAction } from "@reduxjs/toolkit";
import Helpers from "@helpers/utils";
import { IPageableRequest } from "@api/api";
import locale from "antd/lib/date-picker/locale/ru_RU";

const { TextArea } = Input;

interface ICreateNewAnalysis {
  pagination: IPageableRequest;
  createNewAnalysisState: IDrawerCreate;
  dates: ICurrentDate;
  filters: IFilter;
  defaultFilters?: any;
  setCreateNewAnalysisState: React.Dispatch<
    React.SetStateAction<IDrawerCreate>
  >;
  selectedRows: IAnalysisData;
  setSelectedRows: React.Dispatch<React.SetStateAction<IAnalysisData>>;
}

const defaultTableData: INewAnalysisData = [
  {
    analysisRecordId: `${new Date().getTime()}`,
    analysisDate: "",
    analysisActionTypeId: "",
    analysisActWork: 0,
    analysisDescription: "",
    analysisComment: "",
    analysisResource: 0,
    analysisRowTasks: [],
  },
];

interface IRowTasksSearch {
  taskName: string;
  record: INewAnalysisRecord | null;
}

const CreateNewAnalysis: FC<ICreateNewAnalysis> = ({
  pagination,
  createNewAnalysisState,
  setCreateNewAnalysisState,
  dates,
  filters,
  selectedRows,
  setSelectedRows,
  defaultFilters,
}) => {
  const { height, width } = Hooks.useWindowSize();
  const [form] = Form.useForm();
  const tasks = useAppSelector(tasksSelector);
  const projects = useAppSelector(projectsSelector);
  const { taskResources } = useAppSelector(taskResourcesSelector);
  const { id } = useAppSelector(authSelector);
  const analysisTypes = useAppSelector(analysisTypeSelector);
  const dispatch = useAppDispatch();
  const [tableData, setTableData] =
    useState<INewAnalysisData>(defaultTableData);
  const [currentRow, setCurrentRow] = useState("");
  const [rowTaskSearch, setRowTaskSearch] = useState<IRowTasksSearch>({
    taskName: "",
    record: null,
  });

  const { Option } = Select;

  const visibleChange = () => {
    dispatch(
      getTasks({
        taskType: "TASK",
        page: 0,
        userEnterpriseId: id,
        size: 1000,
      })
    );
  };

  const onClose = useCallback(() => {
    setSelectedRows([]);
    setCreateNewAnalysisState(defaultCreateNewAnalysisState);
    form.resetFields();
    setTableData(defaultTableData);
  }, [form, setCreateNewAnalysisState, setSelectedRows]);

  const getEditTableData = useCallback(() => {
    return selectedRows.map(async (el) => {
      const project = projects.content.find(
        (elem) => elem.projTitle === el.projectName
      );

      const values = tasks.content.reduce(
        (prev: TasksApi.IGeResponseContent[], curr) =>
          curr.project?.id === project?.id ? [...prev, curr] : prev,
        []
      );

      const task = values.find((elem) => elem.taskName === el.taskName);

      form.setFieldsValue({
        [`analysisDate-${el.analysisRecordId}`]: moment(el.date),
        [`analysisProjectId-${el.analysisRecordId}`]: project?.id,
        [`task-${el.analysisRecordId}`]: task?.taskName || "",
        [`actionType-${el.analysisRecordId}`]: el.actionType.actionTypeFullName,
        [`effort-${el.analysisRecordId}`]: el.effort,
        [`description-${el.analysisRecordId}`]: el.description,
      });

      const taskResource = (await dispatch(
        getResource(task?.id || "")
      )) as PayloadAction<TasksApi.IResource[]>;

      return {
        analysisRecordId: el.analysisRecordId,
        analysisDate: el.date,
        analysisRowTasks: values,
        analysisProjectId: project?.id,
        analysisResource:
          taskResource.payload.find((el) => el.resEnterpriseId === id)?.id || 0,
        analysisActionTypeId: el.actionType.id,
        analysisActWork: el.effort,
        analysisDescription: el.description,
        analysisComment: el.rejectComment,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, form, projects.content, tasks.content]);

  useEffect(() => {
    if (createNewAnalysisState.isEdit) {
      (async () => {
        const newTableData = await Promise.all(getEditTableData());

        setTableData(newTableData);
      })();
    }
  }, [createNewAnalysisState.isEdit, getEditTableData]);

  const onAnalysisChange = (
    dataIndex: string,
    index: number,
    value: string | number
  ) => {
    const newData = tableData.map((item, itemIndex) =>
      index === itemIndex
        ? {
            ...item,
            [dataIndex]: value,
          }
        : item
    );
    setTableData(newData);
  };

  const onAnalysisTypesChange = (index: number, value: string | number) => {
    const analysisTypeId = _.clone(analysisTypes).find(
      (el: any) => el.actionTypeFullName === value
    )?.id;

    if (!analysisTypeId) return;

    const newData = tableData.map((item, itemIndex) =>
      index === itemIndex
        ? {
            ...item,
            analysisActionTypeId: analysisTypeId,
          }
        : item
    );

    setTableData(newData);
  };

  useEffect(() => {
    dispatch(getAnalysisType());
  }, [dispatch]);

  const onProjectChange = (
    dataIndex: string,
    index: number,
    value: string,
    record: INewAnalysisRecord
  ) => {
    const projectName = projects.content.find(
      (item) => item.id === value
    )?.projTitle;

    const values = tasks.content.reduce(
      (prev: TasksApi.IGeResponseContent[], curr) =>
        curr.project?.id === value ? [...prev, curr] : prev,
      []
    );

    const newData = tableData.map((item, itemIndex) =>
      index === itemIndex
        ? {
            ...item,
            [dataIndex]: value,
            analysisRowTasks: values,
            analysisProjectName: projectName,
          }
        : item
    );

    setTableData(newData);

    if (values.length === 1) {
      setRowTaskSearch({ taskName: values[0].taskName, record: record });
      // onTasksChange(values[0].taskName, record);
      form.setFieldsValue({
        [`task-${record.analysisRecordId}`]: values[0].taskName,
      });
    } else {
      form.setFieldsValue({
        [`task-${record.analysisRecordId}`]: null,
      });
    }
  };

  useEffect(() => {
    if (rowTaskSearch.record) {
      onTasksChange(rowTaskSearch.taskName, rowTaskSearch.record);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowTaskSearch]);

  const onTasksChange = useCallback(
    async (value: string, record: INewAnalysisRecord) => {
      const recordCurrentData = tableData.find(
        (item) => item.analysisRecordId === record.analysisRecordId
      );

      const taskId = recordCurrentData?.analysisRowTasks.find(
        (el: any) => el.taskName === value
      ).id;

      setCurrentRow(record.analysisRecordId); //id записи в которой выбрали таск, чтобы в tableData к нужной записи добавить resources

      //ищем resources для выбранного в строке таска
      if (taskId) {
        await dispatch(getResource(taskId));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData]
  );

  const onSave = useCallback(async () => {
    await form.validateFields();
    form.submit();

    let needToClose = true;

    const tasksForEachWeek: Record<string, analysisApi.IPostContent> = {};
    tableData.forEach((item) => {
      const dates: analysisApi.IPostParams = {
        dateStart: moment(item.analysisDate)
          .startOf("week")
          .format("YYYY-MM-DD"),
        dateFinish: moment(item.analysisDate)
          .endOf("week")
          .format("YYYY-MM-DD"),
      };

      const datesIntervalString = JSON.stringify(dates);

      const newTask = {
        analysisRecordId: createNewAnalysisState.isEdit
          ? item.analysisRecordId
          : undefined,
        analysisResource: item.analysisResource,
        analysisActWork: Number(item.analysisActWork),
        analysisActionTypeId: item.analysisActionTypeId,
        analysisDate: item.analysisDate,
        analysisDescription: item.analysisDescription,
        analysisComment: item.analysisComment,
      };

      tasksForEachWeek[datesIntervalString] = tasksForEachWeek[
        datesIntervalString
      ]
        ? [...tasksForEachWeek[datesIntervalString], newTask]
        : [newTask];
    });

    const requests: analysisApi.IPostRequest[] = Object.entries(
      tasksForEachWeek
    ).map(([week, content]) => ({
      content,
      dates: JSON.parse(week) as analysisApi.IPostParams,
    }));

    if (createNewAnalysisState.isEdit) {
      await dispatch(updateAnalysis(requests[0].content));
    } else {
      for (let i = 0; i < requests.length; i++) {
        const res = await dispatch(postAnalysis(requests[i]));

        if ((res as any).error) {
          needToClose = false;
        }
      }
    }

    if (needToClose) {
      onClose();

      dispatch(
        getAnalysis({
          startDate: _.cloneDeep(dates.dateFrom).format("YYYY-MM-DD"),
          endDate: _.cloneDeep(dates.dateTo).format("YYYY-MM-DD"),
          page: pagination.page - 1,
          size: pagination.size,
          ...filters,
          ...defaultFilters,
        })
      );
    }
  }, [
    createNewAnalysisState,
    dates,
    defaultFilters,
    dispatch,
    filters,
    form,
    onClose,
    pagination,
    tableData,
  ]);

  const onClear = useCallback(() => {
    form.resetFields();

    if (!createNewAnalysisState.isEdit) {
      setTableData(defaultTableData);
    }
  }, [form, createNewAnalysisState.isEdit]);

  const onAdd = () => {
    const key = new Date().getTime();

    setTableData(() => [
      ...tableData,
      {
        analysisRecordId: `${key}`,
        analysisDate: "",
        analysisActionTypeId: "",
        analysisActWork: 0,
        analysisDescription: "",
        analysisComment: "",
        analysisResource: 0,
        analysisRowTasks: [],
      },
    ]);
  };

  useEffect(() => {
    //добавляем resources в tableData
    if (taskResources.length && currentRow !== "") {
      const newData = tableData.map((item: any) =>
        item.analysisRecordId === currentRow
          ? {
              ...item,
              analysisResource:
                taskResources.find((el) => el.resEnterpriseId === id)?.id || 0,
            }
          : item
      );
      setTableData(newData);
    } else if (currentRow !== "") {
      const newData = tableData.map((item: any) =>
        item.analysisRecordId === currentRow
          ? {
              ...item,
              analysisResource: 0,
            }
          : item
      );
      setTableData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskResources]);

  const handleDelete = (key: any) => {
    const names = form.getFieldsValue(); // получаем все имена итемов формы
    const clearFields = Object.keys(names).filter((item) =>
      item.includes(`${key}`)
    ); // ищем имена тех итемов которые надо очистить
    const newData = tableData.filter((item) => item.analysisRecordId !== key); // ищем данные которые надо удалить из tableData
    form.resetFields(clearFields); // очищаем нужные поля формы
    setTableData(newData); //  обновляем tableData
  };

  const handleCopy = (key: any) => {
    const copyData = tableData.find((item) => item.analysisRecordId === key);

    if (!copyData) return;

    const newKey = `${new Date().getTime()}`;

    const newRow: INewAnalysisRecord = {
      analysisRecordId: newKey,
      analysisDate: copyData.analysisDate,
      analysisActionTypeId: copyData.analysisActionTypeId,
      analysisActWork: 0,
      analysisProjectId: copyData.analysisProjectId,
      analysisProjectName: copyData.analysisProjectName,
      analysisDescription: copyData.analysisDescription,
      analysisComment: "",
      analysisResource: copyData.analysisResource,
      analysisRowTasks: copyData.analysisRowTasks,
    };

    setTableData((tableData) => [...tableData, newRow]);

    const formData = form.getFieldsValue();

    form.setFieldsValue({
      [`analysisDate-${newKey}`]:
        formData[`analysisDate-${key}`] === null ||
        formData[`analysisDate-${key}`] === undefined ||
        formData[`analysisDate-${key}`] === ""
          ? ""
          : moment(formData[`analysisDate-${key}`]),
      [`analysisProjectId-${newKey}`]: formData[`analysisProjectId-${key}`],
      [`task-${newKey}`]: formData[`task-${key}`],
      [`actionType-${newKey}`]: formData[`actionType-${key}`],
      [`description-${newKey}`]: formData[`description-${key}`],
    });
  };

  const columns: ColumnsType<INewAnalysisRecord> = [
    {
      title: "Date",
      dataIndex: "analysisDate",
      key: "analysisDate",
      render: (_text, record, index) => (
        <Form.Item
          name={`analysisDate-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <DatePicker
            style={{ width: "100%" }}
            locale={locale}
            format="YYYY-MM-DD"
            onChange={(_date, dateString) =>
              onAnalysisChange("analysisDate", index, dateString)
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "Project",
      dataIndex: "analysisProjectId",
      key: "analysisProjectId",
      render: (_text, record, index) => (
        <Form.Item
          name={`analysisProjectId-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            placeholder="projects"
            className={styles.newAnalysisItem}
            onChange={(value) =>
              onProjectChange("analysisProjectId", index, value, record)
            }
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {_.clone(projects.content)
              .sort((a, b) => Helpers.compare(a.projTitle, b.projTitle))
              .map((el: any) => (
                <Option key={el.id} value={el.analysisProjectId}>
                  {el.projTitle}
                </Option>
              ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Task",
      dataIndex: "analysisResource",
      key: "analysisResource",
      render: (_text, record) => (
        <Form.Item
          name={`task-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            placeholder="tasks"
            className={styles.newAnalysisItem}
            onChange={(value) => onTasksChange(value, record)}
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {record.analysisRowTasks.length
              ? _.clone(record.analysisRowTasks)
                  .sort((a: any, b: any) =>
                    Helpers.compare(a.taskName, b.taskName)
                  )
                  .map((el: any) => (
                    <Option key={el.id} value={el.taskName}>
                      {el.taskName}
                    </Option>
                  ))
              : []}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Action Type",
      dataIndex: "analysisActionTypeId",
      key: "analysisActionTypeId",
      render: (_text, record, index) => (
        <Form.Item
          name={`actionType-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            placeholder="action type"
            className={styles.newAnalysisItem}
            showSearch
            onChange={(value) => onAnalysisTypesChange(index, value)}
          >
            {_.clone(analysisTypes)
              .sort((a, b) =>
                Helpers.compare(a.actionTypeFullName, b.actionTypeFullName)
              )
              .map((el: any) => (
                <Option key={el.id} value={el.actionTypeFullName}>
                  {el.actionTypeFullName}
                </Option>
              ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Effort, h",
      dataIndex: "analysisActWork",
      key: "analysisActWork",
      width: 70,
      render: (text, record, index) => (
        <Form.Item
          name={`effort-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <Input
            placeholder="effort"
            type="number"
            onChange={(e) =>
              onAnalysisChange("analysisActWork", index, e.target.value)
            }
            value={text}
            className={styles.newAnalysisItem}
            onWheel={e => (e.target as HTMLElement).blur()}
          />
        </Form.Item>
      ),
    },
    {
      title: "Description",
      dataIndex: "analysisDescription",
      key: "analysisDescription",
      width: 500,
      render: (_, record, index) => (
        <Form.Item
          name={`description-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <TextArea
            value={record.analysisDescription}
            spellCheck
            placeholder="description"
            autoSize
            onChange={(e) => {
              onAnalysisChange("analysisDescription", index, e.target.value);
            }}
            className={styles.newAnalysisItem}
          />
        </Form.Item>
      ),
    },

    // {
    //   title: createNewAnalysisState.isEdit ? "Reject comment" : "",
    //   dataIndex: "analysisComment",
    //   key: "analysisComment",
    // },
    {
      title: "",
      dataIndex: "copyLine",
      width: 30,
      render: (_, record) =>
        !createNewAnalysisState.isEdit ? (
          <CopyOutlined onClick={() => handleCopy(record.analysisRecordId)} />
        ) : null,
    },
    {
      title: "",
      dataIndex: "operation",
      width: 30,
      render: (_, record) =>
        tableData.length > 1 && !createNewAnalysisState.isEdit ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.analysisRecordId)}
          >
            <DeleteOutlined />
          </Popconfirm>
        ) : null,
    },
  ];

  return (
    <Drawer
      width={width - 10}
      visible={createNewAnalysisState.isVisible}
      onClose={onClose}
      destroyOnClose={true}
      title={`${createNewAnalysisState.isEdit ? "Edit" : "New"} analysis`}
      afterVisibleChange={visibleChange}
    >
      <div className={styles.newAnalysisWrapper}>
        <Form form={form} name="newAnalysis">
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            size="small"
            scroll={{ y: height - 200 }}
            bordered
          ></Table>
        </Form>
        <div className={styles.newAnalysisActions}>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
          {!createNewAnalysisState.isEdit && (
            <Button type="dashed" onClick={onAdd}>
              + Add row
            </Button>
          )}
          <Button onClick={onClear}>Clear</Button>
        </div>
      </div>
    </Drawer>
  );
};

export default CreateNewAnalysis;
