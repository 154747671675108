import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import TasksApi from "@api/tasks";
import Loader from "@components/Loader/Loader";
import {useAppDispatch, useAppSelector} from "@redux/hooks";
import {getOneTask} from "@redux/slices/currentTask";
import {getProjects, projectsSelector} from "@redux/slices/projects";
import {deleteResource} from "@redux/slices/taskResources";
import {getTasks, tasksSelector, updateTaskApprover,} from "@redux/slices/tasks";
import {getUsers, usersSelector} from "@redux/slices/users";
import {Avatar, Button, Select, Table} from "antd";
import {ColumnsType, TablePaginationConfig} from "antd/lib/table";
import {FilterValue, SorterResult, TableCurrentDataSource,} from "antd/lib/table/interface";
import {Dispatch, FC, Key, SetStateAction, useEffect, useState} from "react";
import AddResource from "../AddResource/AddResource";
import styles from "./TaskTable.module.scss";
import {defaultPageSize, defaultTasksSize} from "@helpers/const";

interface ITaskTable {
  selectedRowKeys: Key[];
  setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>;
}

export interface ITableData {
  key: string;
  id: string | number;
  project?: {
    id?: string;
    projTitle?: string;
    projManager?: string;
    projDetails?: string;
  };
  taskRemainWork: number;
  taskName: string;
  taskType: string;
  taskParentTaskId: string | null;
  taskDetails: string;
  taskBaseline: number;
  taskActWork: number | null;
  taskPredTaskId: number | null;
  taskSuccTaskId: number | null;
  taskBaseStartDate: string;
  taskBaseFinishDate: string | null;
  taskActStartDate: string | null;
  taskApprover?: string | null;
  taskActFinishDate: string | null;
  taskChargeCode: string;
  subTasks: any[] | null;
  resources: TasksApi.IResource[] | string | null;
  children?: IExpandResource[] | TasksApi.IResource[] | null;
  isChild?: boolean;
  resEnterpriseId?: string;
  resTaskId?: string;
}

interface IExpandResource {
  id: string;
  resEnterpriseId: string;
  taskBaseLine: number;
  taskActWork: number;
  resTaskId: string;
}

export interface IAddResourceModalState {
  isVisible: boolean;
  resourceId?: number;
  taskId?: string;
}

interface ITableContent {
  tableData: ITableData[];
  currentData: ITableData[];
}

const TaskTable: FC<ITaskTable> = ({ selectedRowKeys, setSelectedRowKeys }) => {
  const tasks = useAppSelector(tasksSelector);
  const [data, setData] = useState<ITableContent>({
    tableData: [],
    currentData: [],
  });
  const users = useAppSelector(usersSelector);
  const dispatch = useAppDispatch();
  const projects = useAppSelector(projectsSelector);
  const [addResourceModal, setAddResourceModal] =
    useState<IAddResourceModalState>({
      isVisible: false,
      taskId: "",
    });
  const [pageState, setPageState] = useState({
    page: 1,
    size: defaultPageSize,
  });

  const formatDate = (date?: string) => {
    if (date) {
      const cut = date.split("T")[0].split("-");
      return cut.reverse().join(".");
    }
  };

  useEffect(() => {
    const data = tasks.content.map((el) => {
      if (el.resources?.length) {
        const children = el.resources.map((res) => ({
          ...res,
          taskBaseline: res.resBaseline,
          taskActWork: res.resActWork,
        }));
        return {
          ...el,
          key: el.id,
          children: Array.isArray(el.resources) ? children : null,
        };
      } else {
        return {
          ...el,
          key: el.id,
        };
      }
    });

    setData(() => ({
      tableData: data,
      currentData: data,
    }));

    dispatch(getUsers({ size: 1000, page: 0 }));

    dispatch(getProjects({ size: 1000, page: 0 }));
  }, [dispatch, tasks]);

  const addResource = (record: ITableData) => {
    setAddResourceModal({
      isVisible: true,
      taskId: record.id as string,
    });
  };

  const editResource = (record: ITableData) => {
    setAddResourceModal({
      isVisible: true,
      taskId: record.resTaskId,
      resourceId: record.id as number,
    });
  };

  const delResource = async (resourceId: number) => {
    try {
      await dispatch(deleteResource(resourceId));

      dispatch(
      getTasks({
        taskType: "TASK",
        page: 0,
        size: defaultTasksSize,
      })
    );
    } catch {}
  };

  const renderResources = (record: ITableData) => {
    if (record.resEnterpriseId) {
      return (
        <div className={styles.resources}>
          {record.resEnterpriseId ? record.resEnterpriseId : ""}
          <div className={styles.resourcesBtns}>
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => editResource(record)}
              style={{ marginLeft: 10 }}
            />
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              style={{ marginLeft: 10 }}
              onClick={() => delResource(record.id as number)}
            />
          </div>
        </div>
      );
    } else if (record.children) {
      const icons = record.children.map((el: any) => {
        return <Avatar>{el.resEnterpriseId[0].toUpperCase()}</Avatar>;
      });
      return (
        <div className={styles.resources}>
          <Avatar.Group
            maxCount={2}
            maxStyle={{
              color: "#FFFFFFF",
              backgroundColor: "#A4D3FF",
            }}
            style={{ marginRight: "10px" }}
          >
            {icons}
          </Avatar.Group>

          <Button onClick={() => addResource(record)} shape="circle">
            +
          </Button>
        </div>
      );
    } else {
      return (
        <Button onClick={() => addResource(record)} shape="circle">
          +
        </Button>
      );
    }
  };

  const changeApprover = (approver: string, record: ITableData) => {
    dispatch(updateTaskApprover({ taskId: record.id, approver: approver }));
  };

  const renderApproverSelect = (approver: string, record: ITableData) => {
    if (Array.isArray(record.resources))
      return (
        <div>
          <Select
            defaultValue={approver}
            placeholder="Не назначен"
            showSearch
            style={{ width: 150 }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value) => changeApprover(value, record)}
            options={users.content.map((el) => {
              return { value: el.id, label: el.userFullName };
            })}
          />
        </div>
      );
  };

  const columns: ColumnsType<ITableData> = [
    {
      title: "Project",
      dataIndex: "project",
      key: "Project",
      width: 250,
      filters: projects.content.map((el) => {
        return { text: el.projTitle, value: el.id };
      }),
      onFilter: (value, record) => {
        return record.project?.id?.indexOf(value as string) === 0
      },
      filterSearch: true,
      render: (project) => {
        return <span>{project && project.projTitle}</span>;
      },
    },
    {
      title: "Task name",
      dataIndex: "taskName",
      key: "Task Name",
      width: 200,
      filters: tasks.content.map((el) => {
        return { text: el.taskName, value: el.taskName };
      }),
      onFilter: (value, record) => record.taskName.startsWith(`${value}`),
      filterSearch: true,
    },
    {
      title: "Task type",
      dataIndex: "taskType",
      key: "Тип  задачи",
    },
    {
      title: "Charge code",
      dataIndex: "taskChargeCode",
      key: "taskChargeCode",
    },
    {
      title: "Resources",
      dataIndex: "resources",
      key: "Resources",
      width: 250,
      render: (_, record) => renderResources(record),
    },
    {
      title: "Baseline",
      dataIndex: "taskBaseline",
      key: "taskBaseLine",
    },
    {
      title: "Actual Work",
      dataIndex: "taskActWork",
      key: "taskActWork",
    },
    {
      title: "Planned dates",
      key: "plannedDates",
      width: 200,
      render: (record) => {
        if (record.taskBaseStartDate) {
          return (
            <span>
              {formatDate(record.taskBaseStartDate)} -{" "}
              {formatDate(record.taskBaseFinishDate)}
            </span>
          );
        } else if (record.resourceStartDate) {
          return (
            <span>
              {formatDate(record.resourceStartDate)} -{" "}
              {formatDate(record.resourceEndDate)}
            </span>
          );
        }
      },
    },
    {
      title: "Actual dates",
      dataIndex: "taskActStartDate",
      key: "actualDates",
      width: 200,
      render: (taskActStartDate: string) => {
        const taskData = tasks.content.filter((el) => {
          return el.taskActStartDate === taskActStartDate;
        });
        return (
          <span>
            {formatDate(taskActStartDate)} -{" "}
            {taskData[0]?.taskActFinishDate
              ? formatDate(taskData[0]?.taskActFinishDate)
              : undefined}
          </span>
        );
      },
    },
    {
      title: "Task status",
      dataIndex: "taskStatus",
      key: "taskStatus",
    },
    {
      title: "Project lead",
      dataIndex: "taskApprover",
      key: "ProjectLead",
      render: (taskApprover: string, record) => {
        return renderApproverSelect(taskApprover, record);
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const taskId: string = `${newSelectedRowKeys[0]}`;
    dispatch(getOneTask(taskId));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<ITableData>
  ) => {

    //TODO: задача по переносу пагинации на фронт (вернуть, когда настроят бэк)

    // dispatch(
    //   getTasks({
    //     taskType: "TASK",
    //     page: 0,
    //     size: pagination.pageSize ?? defaultPageSize,
    //   })
    // );

    // Обновляем текущую страницу
    setPageState({
      page: pagination.current ?? 1,
      size: pagination.pageSize ?? defaultPageSize,
    });
  };

  return (
    <div className={styles.taskTableWrapper}>
      {!data.tableData && <Loader isAbsolute />}

      <Table
        rowKey={(record) => record.id}
        rowSelection={{ type: "radio", ...rowSelection }}
        columns={columns}
        dataSource={data.currentData}
        onChange={onChangeTable}
        pagination={{
          pageSize: pageState.size,
          className: `${styles.taskTablePagination}`,
        }}
        scroll={{
          x: 2000,
          y: "60vh",
        }}
        className={`taskTable ${styles.taskTable}`}
        bordered
      />
      <AddResource
        addResourceModal={addResourceModal}
        setAddResourceModal={setAddResourceModal}
      />
    </div>
  );
};

export default TaskTable;
