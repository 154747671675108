import {
  CURRENT_TIMESHEETS_PAGE,
  ALL_TIMESHEETS_PAGE,
  TASK_CREATION_PAGE,
  ANALYSIS_TOOL_PAGE,
  ANALYSIS_APPROVE_PAGE,
  EXTERNAL_TOOL_PAGE,
} from "@helpers/routes";

export const pageTitles = {
  [CURRENT_TIMESHEETS_PAGE]: "My Timesheets / Current",
  [ALL_TIMESHEETS_PAGE]: "My Timesheets / All Timesheets",
  [TASK_CREATION_PAGE]: "Task Creation",
  [ANALYSIS_TOOL_PAGE]: "Analysis Tool",
  [ANALYSIS_APPROVE_PAGE]: "Analysis Approve",
  [EXTERNAL_TOOL_PAGE]: "External Tool",
};

export const statusConfig: { [key: string]: string } = {
  DRAFT: "gray",
  SUBMITTED: "#389e0d",
};

export const weekDays: { [key: string]: string } = {
  mon: "MONDAY",
  tue: "TUESDAY",
  wed: "WEDNESDAY",
  thu: "THURSDAY",
  fri: "FRIDAY",
  sat: "SATURDAY",
  sun: "SUNDAY",
  MONDAY: "mon",
  TUESDAY: "tue",
  WEDNESDAY: "wed",
  THURSDAY: "thu",
  FRIDAY: "fri",
  SATURDAY: "sat",
  SUNDAY: "sun",
};

export const siderConfig: { [key: string]: boolean } = {
  "/my-timesheets/current": true,
  "/my-timesheets/all": true,
  "/task-creation": true,
  "/analysis-tool": true,
  "/analysis-tool/analysis-approve": true,
  "/external-tool": true,
};

export const analysisRecordTypeOptions = [
  { key: "ANALYSIS", label: "ANALYSIS" },
  { key: "DS", label: "DS" },
];

export const exceptionErrorCodes = [107, 108, 109, 110, 111];

export const jwtErrorCodes = [107, 108, 109, 110];

export const defaultPageSize = 50;
export const defaultTasksSize = 4000;

export enum EAppEnv {
  Localhost = 'localhost', // http://localhost:3000/
  Test = "test", // https://timesheet-retail-team.ax-team.com:55553/
  Preprod = "preprod", // https://timesheet-retail-team.ax-team.com/
}

export interface IAppEnv {
  REACT_APP_ENV: EAppEnv;
}

export const env: IAppEnv = {
  REACT_APP_ENV: process.env.REACT_APP_ENV as EAppEnv,
};