import React, { FC, useEffect } from "react";
import { Select, Modal, Button, Form, Input, DatePicker } from "antd";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { getProjects, projectsSelector } from "@redux/slices/projects";
import { getTasks, postTasks, updateTasks } from "@redux/slices/tasks";
import {
  defaultCreateNewTaskState,
  ICreateNewTaskState,
} from "@containers/TaskCreation/TaskCreation";
import _ from "lodash";
import { currentTaskSelector, getOneTask } from "@redux/slices/currentTask";
import moment, { Moment } from "moment";
import styles from "./CreateNewTask.module.scss";

interface ICreateNewTask {
  createNewTaskState: ICreateNewTaskState;
  setCreateNewTaskState: React.Dispatch<
    React.SetStateAction<ICreateNewTaskState>
  >;
}

interface IForm {
  id: string;
  taskName?: string;
  taskDetails: string;
  taskChargeCode: string;
  taskDates: Moment[];
  taskBaseline: number;
  taskProjectId: string;
}

const { Option } = Select;
const CreateNewTask: FC<ICreateNewTask> = ({
  createNewTaskState,
  setCreateNewTaskState,
}) => {
  const [form] = Form.useForm<IForm>();
  const { content } = useAppSelector(projectsSelector);
  const { task } = useAppSelector(currentTaskSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (createNewTaskState.isEdit) {
      form.setFieldsValue({
        id: task.id,
        taskName: task.taskName,
        taskDetails: task.taskDetails,
        taskChargeCode: task.taskChargeCode,
        taskDates: [
          moment(task.taskBaseStartDate),
          moment(task.taskBaseFinishDate),
        ],
        taskBaseline: task.taskBaseline,
        taskProjectId: task.project?.id,
      });
    }
  }, [task, form, createNewTaskState]);

  useEffect(() => {
    if (createNewTaskState.isVisible) {
      dispatch(getProjects({ size: 1000, page: 0 }));
    }
  }, [dispatch, createNewTaskState]);

  const handleOk = async () => {
    const options: {
      taskType: string;
      taskParentTaskId?: string;
    } = {
      taskType: createNewTaskState.taskType,
    };

    if (createNewTaskState.taskId) {
      options.taskParentTaskId = createNewTaskState.taskId;
    }

    const {
      taskDates: [taskBaseStartDate, taskBaseFinishDate],
      ...reqData
    } = form.getFieldsValue();

    if (createNewTaskState.taskId) {
      options.taskParentTaskId = createNewTaskState.taskId;
    }

    if (createNewTaskState.isEdit) {
      if (reqData.taskName === task.taskName && reqData.taskName) {
        delete reqData.taskName;
      }

      await dispatch(
        updateTasks({
          ...options,
          ...reqData,
          taskBaseStartDate,
          taskBaseFinishDate,
        })
      );
    } else {
      await dispatch(
        postTasks({
          ...options,
          ...reqData,
          taskBaseStartDate,
          taskBaseFinishDate,
        })
      );
    }

    if (createNewTaskState.taskType === "TASK") {
      dispatch(
        getTasks({
          taskType: createNewTaskState.taskType,
          page: 0,
          size: 4000,
        })
      );
    } else {
      dispatch(getOneTask(createNewTaskState.taskId || ""));
    }
    setCreateNewTaskState(defaultCreateNewTaskState);
    form.resetFields();
  };

  const handleCancel = () => {
    setCreateNewTaskState(defaultCreateNewTaskState);
    form.resetFields();
  };

  return (
    <Modal
      visible={createNewTaskState.isVisible}
      title={`${createNewTaskState.isEdit ? "Edit" : "Create"}  ${_.upperFirst(
        createNewTaskState.taskType.toLocaleLowerCase()
      )}`}
      onCancel={handleCancel}
      onOk={handleOk}
      width={660}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="addResource"
        initialValues={{ remember: true }}
        autoComplete="off"
        className={styles.addTaskForm}
      >
        <Form.Item name="taskName">
          <Input
            bordered={false}
            className="customInput"
            placeholder="Введите имя задачи"
          />
        </Form.Item>

        <Form.Item name="taskChargeCode">
          <Input
            bordered={false}
            className="customInput"
            placeholder="Task Charge Code"
          />
        </Form.Item>
        <Form.Item name="taskDates">
          <DatePicker.RangePicker
            className="customDataPicker"
            placeholder={["Start Date", "End Date"]}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name="taskBaseline">
          <Input
            placeholder="Task Baseline"
            type='number'
            bordered={false}
            className="customInput"
            onWheel={e => (e.target as HTMLElement).blur()}
          />
        </Form.Item>
        <Form.Item name="taskProjectId">
          <Select
            bordered={false}
            className="customSelect"
            placeholder="Task Project Name"
          >
            {content.map((el) => (
              <Option value={el.id}>{el.projTitle}</Option>
            ))}
          </Select>
        </Form.Item>
        <></>
        <Form.Item name="taskDetails">
          <Input
            bordered={false}
            className="customInput"
            placeholder="Task Details"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNewTask;
