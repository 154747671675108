import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@api/index";
import TasksApi from "@api/tasks";
import { defaultPageableContent } from "@api/const";

const initialState: TasksApi.IGetResponse = {
  ...defaultPageableContent,
};

export const getTasks = createAsyncThunk(
  "tasks/getTasks",
  async (payload: TasksApi.IGetRequest) => {
    const { data } = await Api.tasks.get(payload);

    return data;
  }
);

export const putAddResource = createAsyncThunk(
  "tasks/putAddResource",
  async (payload: TasksApi.IAddResourceRequest) => {
    const { data } = await Api.tasks.addResource(payload);

    return data;
  }
);

export const postTasks = createAsyncThunk(
  "tasks/postTasks",
  async (payload: any) => {
    const { data } = await Api.tasks.post(payload);

    return data;
  }
);

export const updateTasks = createAsyncThunk(
  "tasks/updateTasks",
  async (payload: any) => {
    const { data } = await Api.tasks.put(payload);

    return data;
  }
);

export const updateTaskApprover = createAsyncThunk(
  "tasks/updateTaskApprover",
  async (payload: any) => {
    const { data } = await Api.tasks.patchApprover(payload);

    return data;
  }
);

export const deleteTasks = createAsyncThunk(
  "tasks/deleteTasks",
  async (payload: string) => {
    const { data } = await Api.tasks.del(payload);

    return data;
  }
);

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getTasks.fulfilled,
        (_, action: PayloadAction<TasksApi.IGetResponse>) => action.payload
      )
      .addCase(
        postTasks.fulfilled,
        (state, action: PayloadAction<TasksApi.IGeResponseContent | null>) => {
          if (action.payload) {
            const taskIndex = state.content.findIndex(
              (el) => el.id === action.payload?.taskParentTaskId
            );
            const parent = state.content[taskIndex];
            const first = state.content.slice(0, taskIndex);
            const second = state.content.slice(taskIndex + 1);

            const stateContent = [
              ...first,
              {
                ...parent,
                subTasks: parent?.subTasks
                  ? [...parent.subTasks, action.payload]
                  : [action.payload],
              },
              ...second,
            ];
            return { ...state, content: stateContent };
          }
          return state;
        }
      );
  },
});

export const { clear } = tasksSlice.actions;

export const tasksSelector = (state: RootState) => state.tasks;

export default tasksSlice.reducer;
